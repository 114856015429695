import React from "react";

import "./SearchItem.scss";
import {Grid} from "@material-ui/core";
import {Locale} from "ias-lib";
import {useHistory} from "react-router";

export type SearchItem = {
    type: "booking"|"quotation"|"client"|"partner"|"transport",
    reference: string,
    id: UUID,
    detail: string,
    link: string;
}


type Props = {
    item: SearchItem;
    onClick: () => void;
}

export const SearchItem: React.FunctionComponent<Props> = ({item, onClick}) => {
    const history = useHistory();
    let detail = item.detail;
    const values = detail.match("^([A-Z]{2})-([A-Z]{2})$");
    if(values !== null) {
        detail = Locale.trans('countries.' + values[1]) + '-' + Locale.trans('countries.' + values[2]);
    }
    return (
        <Grid container className={"search-item clickable"} onClick={() => {
            history.push(item.link);
            onClick();
        }}>
            <Grid item xs={3} className={"type"}>{Locale.trans(item.type)}</Grid>
            <Grid item xs={4} className={"reference"}>{item.reference}</Grid>
            <Grid item xs={5} className={"details"}>{detail}</Grid>
        </Grid>
    );
}


export default SearchItem;