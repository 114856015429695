import React, {useContext} from "react";
import { useSnackbar } from 'notistack';
import {Locale} from "ias-lib";
import {updateAuthToken as updateTokenApollo} from '../services/AppoloClient';

let AUTH_TOKEN: AuthTokenType | null = null;
let listener: null | AuthListener= null;

const AuthContext = React.createContext<State>({
  isAuth: false,
  authToken : null,
  updateAuthToken: () => {},
});

type AuthListener = (arg: AuthTokenType | null) => void;

type Props = {
}
type State = {
  isAuth: boolean,
  authToken: AuthTokenType  | null,
  updateAuthToken: AuthListener;
}

class AuthProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const authTokenStr = localStorage.getItem("auth-token");
    const authToken: AuthTokenType | null = authTokenStr ? JSON.parse(authTokenStr) : null;

    AUTH_TOKEN = authToken;
    listener = this.updateAuthToken;
    updateTokenApollo(authToken);

    this.state = {
      isAuth: !!authToken,
      authToken,
      updateAuthToken: this.updateAuthToken
    };
  }

  updateAuthToken = (authToken: AuthTokenType | null) => {
    if (authToken) {
      const filtered = {
        id: authToken.id,
        value: authToken.value,
        user: {
          id: authToken.user?.id,
          //@ts-ignore
          endUserLicenseAgreementAccepted: authToken.user?.endUserLicenseAgreementAccepted,
          lastName: authToken.user?.lastName,
          firstName: authToken.user?.firstName,
        },
        createdAt: authToken.createdAt,
        // id: authToken.id,
      };
      localStorage.setItem("auth-token", JSON.stringify(filtered));
    } else {
      localStorage.removeItem("auth-token");
    }
    updateTokenApollo(authToken);

    AUTH_TOKEN = authToken;
    this.setState({
      isAuth: !!authToken,
      authToken
    });
  };

  render() {
    return (
        <AuthContext.Provider value={this.state}>
          {this.props.children}
        </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

/**
 * We use this function to be able to access
 * the auth token from outside React components.
 */
function getAuthToken(): AuthTokenType | null {
  return AUTH_TOKEN;
}

function getUrlFormatedToken() {
  const token = getAuthToken();
  return token ? token.value.replace(/\+/g, "%2B") : "";
}
function useLogout() {
  const {enqueueSnackbar} = useSnackbar();
  function logout() {
    if (listener != null) {
      listener(null);
    }
    enqueueSnackbar(Locale.trans('login.logged_out'), {variant: 'error'});
  }
  return logout;
}

/**
 * Should not be used except if you re
 * @constructor
 */
const UNSAFE_logout = () => {
  if (listener != null) {
    listener(null);
  }
}

const useAuthContext = (): State => {
  const context = useContext(AuthContext);
  return context;
}

export {
  AuthProvider,
  AuthConsumer,
  getAuthToken,
  getUrlFormatedToken,
  useLogout,
  useAuthContext,
  UNSAFE_logout,
};
