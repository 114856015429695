

const FAST_POLL_RATE = 5000;
const MEDIUM_POLL_RATE = 30000;
const SLOW_POLL_RATE = 180000;

export {
    FAST_POLL_RATE,
    MEDIUM_POLL_RATE,
    SLOW_POLL_RATE,
}