import React from "react";
import {InputBase} from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import {Locale} from "ias-lib";


type Props = {
    onChange: (search: string) => void;
    value: string|null;
}

export const SearchInput: React.FunctionComponent<Props> = ({onChange, value}) => {

    return (
        <InputBase
            //We've put a setTimeOut here, otherwise the blur is thrown before the click on the searchItem.
            onBlur={() => setTimeout(() => onChange(""), 300)}
            onFocus={(e) => onChange(e.target.value)}
            autoComplete="off"
            placeholder={Locale.trans('menu.search')}
            onChange={(e) => onChange(e.target.value)}
            value={value}
            startAdornment={
                <SearchOutlinedIcon className="search-icon" />
            }
            className={"search-input"}
            inputProps={{
                'aria-label': 'search',
            }}
        />
    );
}


export default SearchInput;