import * as React from "react";
import {Badge, IconButton} from "@material-ui/core";
import bell from '../../../images/bell.svg';

import './NotificationButton.scss';

type Props = {
    onOpen: (event: React.MouseEvent<HTMLElement>) => void;
    nbNotification: number;
    isOpen: boolean;
    buttonClassName?: string;
}

export const NotificationButton: React.FunctionComponent<Props> = ({buttonClassName, nbNotification, onOpen, isOpen}) => {
    return (
        <>
            <IconButton
                className={`${buttonClassName} notification-button ${isOpen ? 'opened' : 'closed'}`}
                // className={clsx(classes.appBarIcon)}
                aria-label="show new notifications"
                onClick={onOpen}
                color="inherit"
                disableRipple
            >
                <Badge
                    badgeContent={nbNotification}
                    color="primary"
                    className={"notification-badge"}
                >
                    <img
                        className={isOpen ? "bell-opened" : ""}
                        src={bell}
                        alt={'logo notification'}
                    />
                </Badge>
            </IconButton>
        </>
    );
}
export default NotificationButton;    