import React, {useState} from "react";
import SearchInput from "./SearchInput";
import {Card, CardContent, Grid, Menu} from "@material-ui/core";
import {Locale} from "ias-lib";
import {gql, useQuery} from "@apollo/client";
import SearchItem from "./SearchItem";

import './Search.scss';

type Props = {}

const SEARCH = gql`
    query search($query: String!) {
        search(query: $query) {
            detail
            id
            reference
            type
            link
        }
    }
`;

export const Search: React.FunctionComponent<Props> = ({}) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string|null>('');

    const {data, error, loading} = useQuery(SEARCH, {
        variables: {query: search},
        fetchPolicy: "cache-and-network"
    });

    const isEmpty = !(loading || error) && data?.search.length === 0;


    function handleChange(search: string) {
        if(search === "") {
            setOpen(false);
            setSearch('');
            return;
        }
        if(!isOpen) {
            setOpen(true);
        }
        setSearch(search);
    }

    const onClickItem = () => {
        setOpen(false);
        setSearch("")
    }


    return (
        <div className="search">
            <SearchInput value={search} onChange={handleChange} />
            <div className={`menu ${isOpen ? 'open': 'closed'}`}>
                <Card>
                    {loading || isEmpty || error ? (
                        <CardContent className={`info ${isEmpty ? 'empty' : 'loading'}`}>
                            {isEmpty ? (
                                Locale.trans('search.no_result')
                            ) : !loading ? (
                                Locale.trans('search.error')
                            ) : (
                                Locale.trans('search.loading')
                            )}
                        </CardContent>
                    ): data?.search.map((search: SearchItem) => (
                        <SearchItem
                            key={search.reference}
                            item={search}
                            onClick={onClickItem}
                        />
                    ))}
                </Card>
            </div>
            {/*<Menu*/}
            {/*    anchorOrigin={{*/}
            {/*        horizontal: 'left',*/}
            {/*        vertical: 'bottom',*/}
            {/*    }}*/}
            {/*    transformOrigin={{*/}
            {/*        horizontal: 'left',*/}
            {/*        vertical: 'top',*/}
            {/*    }}*/}
            {/*    anchorReference="anchorEl"*/}
            {/*    id="menu-search"*/}
            {/*    anchorEl={anchorElement}*/}
            {/*    keepMounted*/}
            {/*    open={Boolean(anchorElement)}*/}
            {/*    onClose={close}*/}
            {/*    getContentAnchorEl={null}*/}
            {/*    autoFocus={false}*/}
            {/*    disableAutoFocusItem={false}*/}
            {/*>*/}
            {/*    <Grid container>*/}
            {/*        {[].map((item : any) => {*/}
            {/*            return(*/}
            {/*                <Grid*/}
            {/*                    item*/}
            {/*                    xs={12}*/}
            {/*                    className={"menu-search__item"}*/}
            {/*                    key={item.id}*/}
            {/*                >*/}
            {/*                    <SearchItem item={item} />*/}
            {/*                </Grid>*/}
            {/*            );*/}
            {/*        })}*/}
            {/*    </Grid>*/}
            {/*</Menu>*/}
        </div>
    );
}


export default Search;