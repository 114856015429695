import React, { useContext, useState } from "react";

import { frFR, enUS } from '@material-ui/core/locale';

import "./App.scss";

// Router
import { ProtectedRoute } from "components";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "shared/src/contexts/AuthContext";
import { ApolloProvider } from '@apollo/client';
import client from "shared/src/services/AppoloClient";
import {SnackbarProvider} from "notistack";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import {Locale} from "ias-lib";

import fr from "./trans/messages.fr";
import en from "./trans/messages.en";
// Views
import Layout from "views/Layout";
import Login from "views/login/Login";
import ResetPasswordRequest from "views/login/ResetPasswordRequest";
import Unauthorised from "./views/Unauthorised";
import Inactive from "./views/Inactive";
import ForgottenPasswordRequest from "./views/login/ForgottenPasswordRequest";
//import LocaleContext, { LocaleProvider } from "contexts/LocaleContext";
import LocaleContext, { LocaleProvider } from "shared/src/contexts/LocaleContext";
import {MenuManagementProvider} from "shared/src/contexts/MenuManagementContext";
 
const options = {
    palette: {
        primary: {
            /**
             * Preview: ![green 50](https://material-ui.com/static/colors-preview/green-50-24x24.svg)
             */
            main: '#00B796',
        },
    },
};

const ThemeProviderWrapper = () => {
    const {locale} = useContext(LocaleContext);
    const [locales] = useState({
        fr: frFR,
        en: enUS,
    }) ;
    return (
        <ThemeProvider theme={createMuiTheme(options, locales[locale])}>
              <Router>
                  <Switch>
                      <Route exact path="/login" component={Login}/>
                      <Route exact path="/reset-password-request" component={ResetPasswordRequest}/>
                      <Route exact path="/reset-password" component={ForgottenPasswordRequest}/>
                      <Route exact path="/unauthorised" component={Unauthorised} />
                      <Route exact path="/inactive" component={Inactive}/>
                      <ProtectedRoute path="/" component={Layout}/>
                  </Switch>
              </Router>
    </ThemeProvider>
    )
};

function App() {

  Locale.addTranslation({fr});
  Locale.addTranslation({en});

  return (
      <ApolloProvider client={client}>
          <SnackbarProvider
              maxSnack={3}
              preventDuplicate={true}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
          >
              <div className="App">
                <AuthProvider>
                    <MenuManagementProvider>
                        <LocaleProvider>
                          <ThemeProviderWrapper />
                        </LocaleProvider>
                    </MenuManagementProvider>
                </AuthProvider>
              </div>
          </SnackbarProvider>
      </ApolloProvider>
  );
}

Locale.setLocale('fr');

export default App;
