import {Button, Theme, withStyles} from "@material-ui/core";

const ActionButton = withStyles((theme: Theme) => ({
    root: {
        color: '#FFFFFF',
        backgroundColor: '#000000',
        padding: '14px 25px',
        '&:hover': {
            backgroundColor: '#333333',
        },
        float: 'right',
        fontFamily: 'Eesti, sans-serif',
        textTransform: "lowercase",
        borderRadius: "8px"
    },
    disabled: {
        backgroundColor: '#888888',
        color: '#FFFFFF',
    }
}))(Button);

export default ActionButton;
