import * as React from 'react';
import {Locale} from "ias-lib";
import {AppBar, createStyles, fade, IconButton, Link, Paper, Theme, Typography} from "@material-ui/core";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../images/logo couleur.png";
import logoIcon from "../images/logo-icon.png";
import {useLogout} from "shared/src/contexts/AuthContext";
import {makeStyles} from "@material-ui/core/styles";
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import {useHistory} from 'react-router-dom';
import {ADMIN, useIsGranted} from "../services/useIsGranted";
import Parameters from "parameters";

type Props = {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        appBar: {
            background: 'white',
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: "#9fa5ab",
        },
        inputRoot: {
            color: "#9fa5ab",
            width: '100%',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        appBarIcon: {
            color: '#101e2c',
            marginRight: '25px',
        },
        newButton: {
            color: 'white',
            marginRight: '25px',
        },
        title: {
            //display: 'block',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            backgroundColor: '#101E2C',
            color: 'white',
            marginLeft: '5%',
            marginRight: '5%',
        },
        maxHeight: {
            height: '100%',
        },
        searchMenu: {
            backgroundColor: 'white',
            borderRadius: '2px',
            color: '#101E2C',
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        container: {
            display: 'flex',
            flexDirection: "column",
            height: '100vh',
        },
        content: {
            backgroundColor: "#10101010",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
        },
        card: {
            width: "50%",
            minHeight: "30%",
            padding: '20px',
            textAlign: "center",
        },
        mainTitle: {
            marginBottom: '20px',
        },
    }));

const Unauthorised: React.FunctionComponent<Props> = () => {
    const {isGranted, loading} = useIsGranted();
    const logout = useLogout();
    const history = useHistory();
    const classes = useStyles();
    return (
        <>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar)}
            >
                <Toolbar>
                    <img
                        src={logo}
                        style={{marginLeft: '0',}}
                        alt={Locale.trans("logo.fleetever")}
                        height={"40px"}
                        className={clsx(classes.sectionDesktop)}
                        onClick={() => {
                            history.push('/');
                        }}
                    />
                    <img
                        style={{marginLeft: '0',}}
                        src={logoIcon}
                        alt={Locale.trans("logo.fleetever")}
                        height={"40px"}
                        className={clsx(classes.sectionMobile)}
                        onClick={() => {
                            history.push('/');
                        }}
                    />
                    <div className={classes.grow} />
                    <IconButton
                        edge="end"
                        className={clsx(classes.appBarIcon)}
                        aria-label="account of current user"
                        aria-haspopup="true"
                        onClick={() => {
                            logout();
                            history.push('/login');
                        }}
                        color="inherit"
                    >
                        <MeetingRoomIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={classes.container}>
                <div className={classes.drawerHeader}/>
                <div className={classes.content}>
                    <Paper className={classes.card}>
                    <Typography variant="h3" className={classes.mainTitle}>
                        {Locale.trans('error.unauthorised.title')}
                    </Typography>
                    {!loading && isGranted(ADMIN) && (<Typography variant="body1">
                        {Locale.trans('error.unauthorised.m2r')}
                    </Typography>)}
                    {!loading && isGranted(ADMIN) &&  (<Typography variant="body1">
                        {Locale.trans('error.unauthorised.other')}
                        <br />
                            <Link href={Parameters.ClientURL} component="button">
                                {Locale.trans('error.unauthorised.other_button')}
                            </Link>
                    </Typography>)}
                    </Paper>
                </div>
            </div>
        </>
    );
}
export default Unauthorised;
