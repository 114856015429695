/* eslint-disable no-undef */
const Parameters = {
  ApiUrl: API_URL,
  env: (ENV || 'dev'),
  showNote: SHOW_NOTE,
  supportMail: SUPPORT_MAIL,
  appVersion: APP_VERSION
};

export default Parameters;
