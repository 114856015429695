import * as React from 'react';
import "./error.scss";

type Props = {}

const e404: React.FunctionComponent<Props> = () => {
    return (
        <div className={"error-page list-page"}>
            <div className={"error-container"}>
                ERROR 404
            </div>
        </div>
    );
}
export default e404;
