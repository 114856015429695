import React from 'react';

import clsx from "clsx";

import {createStyles, makeStyles, withStyles} from "@material-ui/core/styles";
import {Button, CircularProgress, Grid} from "@material-ui/core";
import {Locale} from "ias-lib";
import ActionButton from "../../components/MaterialUi/ActionButton";
import {useForm} from 'react-form'
import {useHistory} from 'react-router-dom';
import Parameters from 'parameters';
import PasswordField from "shared/src/components/Fields/PasswordField/PasswordField";
import UsernameField from "shared/src/components/Fields/UsernameField/UsernameField";


const useStyles = makeStyles(() =>
    createStyles({
        item: {
            width: '100%',
            marginTop: '15px',
            marginBottom: '15px',
        },
    })
);

const ForgottenButton = withStyles(() => ({
    root: {
        color: '#00B796',
        paddingLeft: 0,
        padding: '14px',
        fontSize: '14px',
        fontFamily: "Lato, sans-serif",
        fontWeight: 'normal',
        textTransform: 'none'
    },
}))(Button);


type Props = {
    onLogin: (login: string, password: string, arg: boolean) => void;
}

export default function LoginForm({onLogin}: Props) {
    const {
        Form,
        meta: {isSubmitting, canSubmit}
    } = useForm({
        onSubmit: async (values: any) => {
            await onLogin(values.username as string, values.password as string, false);
        },
        debugForm: Parameters.env === "dev",
    });
    const classes = useStyles();
    const history = useHistory();


    return (
        <Form>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <UsernameField className={clsx(classes.item)} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PasswordField className={clsx(classes.item)} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ForgottenButton onClick={() => history.push('/reset-password-request')}>
                        {Locale.trans('login.username_forgotten')}
                    </ForgottenButton>
                    <ActionButton
                        type="submit"
                        disabled={!canSubmit}
                        startIcon={isSubmitting ? (<CircularProgress/>) : null}
                    >
                        {Locale.trans('login.login')}
                    </ActionButton>
                </Grid>
            </Grid>
        </Form>
    );
}



