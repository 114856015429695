import * as React from 'react';

import {makeStyles, Theme, createStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import {Locale} from "ias-lib";
import {
    AppBar, Grid,
    IconButton,
    Menu,
    MenuItem,
} from "@material-ui/core";


import MenuComponent from "shared/src/components/Menu/Menu";

import speedometer from 'images/speedometer-3.svg';
import bill from 'images/bill-2.svg';
import check from 'images/check-in-2.svg';
import tags from 'images/tags-stack.svg';
import contact from 'images/contact-86.svg';
import event from 'images/event-create.svg';
import users from 'images/users-mm.svg';
import speedometerSelected from 'images/speedometer-3-selected.svg';
import billSelected from 'images/bill-2-selected.svg';
import checkSelected from 'images/check-in-2-selected.svg';
import tagsSelected from 'images/tags-stack-selected.svg';
import eventSelected from 'images/event-create-selected.svg';
import usersSelected from 'images/users-mm-selected.svg';

import {AuthConsumer, useLogout} from "shared/src/contexts/AuthContext";
import {useHistory} from "react-router";
import {useQuery} from "@apollo/client";
import {GET_NOTIFICATIONS} from "./gql";
import useCurrentUser from "shared/src/hooks/useCurrentUser";
import Parameters from '../../parameters';
import {SLOW_POLL_RATE} from "../../const/PollRate";
import {Skeleton} from "@material-ui/lab";
import {Notifications} from "shared/src/components/Layout/Notification/Notifications";
import {Search} from "shared/src/components/Search/Search";
import Logo from "shared/src/components/Layout/AppBarComponents/Logo";
import "./AppBarComponent.scss";
import LanguageSelect from 'shared/src/components/Country/LanguageSelect';
import {useMenuManagement} from "shared/src/contexts/MenuManagementContext";
import {ROLE_ACCESS_CLIENT_PAYMENTS, ROLE_ACCESS_PARTNER_PAYMENTS, useIsGranted} from "../../services/useIsGranted";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
            marginLeft: '0.3rem',
        },
        fontFamily: {
            fontFamily: "Eesti",
            fontWeight: "normal",
        },
        appBar: {
            background: 'white',
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            boxShadow: 'none',
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        appBarIcon: {
            color: '#101e2c',
            marginLeft: '25px',
            borderRadius: '10px',

        },
        loggout: {
            fontWeight: "bold",
            color: "#00B796",
        },
    }));

type Props = {}

type T_MenuItem = MenuItemToRender | MenuItemRendered;
type MenuItemToRender = {
    name: string;
    url: string;
    icon?: JSX.Element;
    iconSelected?: JSX.Element;
    subMenus?: MenuItemToRender[];
    hidden?: boolean;
};
type MenuItemRendered = { render: () => JSX.Element };


const AppBarComponent: React.FunctionComponent<Props> = () => {
    const logout = useLogout();
    const classes = useStyles();

    const { isGranted } = useIsGranted();

    const {isMenuOpen, toggleMenuOpen} = useMenuManagement();

    const [profileMenuOpenAnchorEl, setProfileMenuOpenOpenAnchorEl] = React.useState<null | HTMLElement>(null);
    const {currentUser} = useCurrentUser();


    const {data: notificationsData, refetch} = useQuery(GET_NOTIFICATIONS, {
        pollInterval: Parameters.env === "dev" ? undefined : SLOW_POLL_RATE,
        fetchPolicy: "cache-and-network"
    });

    const history = useHistory();

    const menuItems: T_MenuItem[] = [
        {
            name: Locale.trans("menu.dashboard"),
            icon: <img src={speedometer} alt="speedometer-logo"/>,
            iconSelected: <img src={speedometerSelected} alt="speedometer-logo"/>,
            url: "/",
        },
        {
            name: Locale.trans("menu.quotations"),
            icon: <img src={tags} alt="quotations-logo"/>,
            iconSelected: <img src={tagsSelected} alt="quotations-logo"/>,
            url: "/quotations",
        },
        {
            name: Locale.trans("menu.booking"),
            icon: <img src={event} alt={"booking logo"}/>,
            iconSelected: <img src={eventSelected} alt={"booking logo"}/>,
            url: "/booking",
        },
        {
            name: Locale.trans("menu.transport"),
            icon: <img src={check} alt={"transport logo"}/>,
            iconSelected: <img src={checkSelected} alt={"transport logo"}/>,
            url: "/transport",
        },
        {
            name: Locale.trans("menu.marketplace"),
            icon: <img src={users} alt={"contacts logo"}/>,//TODO
            iconSelected: <img src={usersSelected} alt={"contacts logo"}/>,//TODO
            url: "/marketplace/transports",
            subMenus: [
                {
                    name: Locale.trans("menu.transports"),
                    url: "/marketplace/transports"
                },
                {
                    name: Locale.trans("menu.discussions"),
                    url: "/marketplace/discussions"
                },
                {
                    name: Locale.trans("menu.offers"),
                    url: "/marketplace/offers"
                },
            ]
        },
        {
            name: Locale.trans("menu.clients"),
            icon: <img src={users} alt={"contacts logo"}/>,//TODO
            iconSelected: <img src={usersSelected} alt={"contacts logo"}/>,//TODO
            url: "/clients",
        },
        {
            name: Locale.trans("menu.clientPayments"),
            icon: <img src={bill}  alt={"bills logo"}/>,//TODO
            iconSelected: <img src={billSelected} alt={"bills logo"}/>,//TODO
            url: "/payments/client",
            hidden: !isGranted(ROLE_ACCESS_CLIENT_PAYMENTS)
        },

        {
            name: Locale.trans("menu.partners"),
            icon: <img src={bill} alt={"bills logo"}/>,//TODO
            iconSelected: <img src={billSelected} alt={"bills logo"}/>,//TODO
            url: "/partners",
        },
        {
            name: Locale.trans("menu.partnerPayments"),
            icon: <img src={bill} alt={"bills logo"}/>,//TODO
            iconSelected: <img src={billSelected} alt={"bills logo"}/>,//TODO
            url: "/payments/partner",
            hidden: !isGranted(ROLE_ACCESS_PARTNER_PAYMENTS)
        },
        {
            name: Locale.trans("menu.reminders"),
            icon: <img src={bill} alt={"bills logo"}/>,//TODO
            iconSelected: <img src={billSelected} alt={"bills logo"}/>,//TODO
            url: "/reminders",
        },
        {
            name: Locale.trans("menu.providers"),
            icon: <img src={bill} alt={"bills logo"}/>,//TODO
            iconSelected: <img src={billSelected} alt={"bills logo"}/>,//TODO
            url: "/providers",
        },
        {
            name: Locale.trans("menu.settings"),
            icon: <img src={bill} alt={"bills logo"}/>,//TODO
            iconSelected: <img src={billSelected} alt={"bills logo"}/>,//TODO
            url: "/settings",
        },
        {
            name: Locale.trans("menu.tools"),
            icon: <img src={bill} alt={"tools logo"}/>,//TODO
            iconSelected: <img src={billSelected} alt={"tools logo"}/>,//TODO
            url: "/tools",
        },
    ];

    const MenuItemsDesktop = menuItems;

    const MenuItemsMobile = menuItems;


    const handleMenuToggle = () => {
        toggleMenuOpen();
    };

    const handleProfileMenuClose = () => {
        setProfileMenuOpenOpenAnchorEl(null);
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setProfileMenuOpenOpenAnchorEl(event.currentTarget);
    };


    const menuId = 'primary-search-account-menu';

    const notifications = notificationsData ? notificationsData.notifications.notifications : [];
    return (
        <AuthConsumer>
            {({authToken}) => {
                return (
                    <>
                        <AppBar
                            position="fixed"
                            className={clsx(classes.appBar)}
                        >
                            <Toolbar className={"app-bar"}>
                                <Grid container>
                                    <Grid item className={`logo-container ${(isMenuOpen ? "open" : 'closed')}`}>
                                        <Logo
                                            isMenuOpen={isMenuOpen}
                                            handleMenuToggle={handleMenuToggle}
                                        />
                                    </Grid>
                                    <Grid item className={"desktop search-container"}>
                                        <Search/>
                                    </Grid>
                                    <Grid item className={"desktop buttons-container"}>

                                        <LanguageSelect/>
                                        <Notifications
                                            notifications={notifications}
                                            total={notificationsData ? notificationsData.notifications.total : 0}
                                            buttonClassName={"appBarIcon"}
                                            refetch={refetch}
                                        />
                                    </Grid>
                                    <Grid item className={"desktop"}>
                                        <div
                                            className="profile-container clickable"
                                            onClick={handleProfileMenuOpen}
                                        >
                                            <p
                                                className={clsx(classes.appBarIcon, classes.fontFamily)}
                                                aria-controls={menuId}
                                                aria-haspopup="true"
                                                color="inherit"
                                            >
                                                {currentUser ?
                                                    (<>{currentUser.firstName} {currentUser.lastName}</>)
                                                    :
                                                    <Skeleton variant="text" animation="wave" width={140} height={35}/>
                                                }
                                            </p>
                                            <IconButton
                                                edge="end"
                                                className={clsx(classes.appBarIcon)}
                                                aria-label="account of current user"
                                                aria-controls={menuId}
                                                aria-haspopup="true"
                                                color="inherit"
                                                disableRipple={true}
                                            >
                                                <img src={contact}
                                                     alt={"logo user"}/>
                                            </IconButton>
                                        </div>
                                    </Grid>
                                    <div className={classes.sectionMobile}>
                                        <Notifications notifications={notifications} buttonClassName={"appBarIcon"}
                                                       refetch={refetch}/>
                                        <IconButton
                                            edge="end"
                                            className={clsx(classes.appBarIcon)}
                                            aria-label="account of current user"
                                            aria-controls={menuId}
                                            aria-haspopup="true"
                                            onClick={handleProfileMenuOpen}
                                            color="inherit"
                                            disableRipple={true}
                                        >
                                            <img src={contact}
                                                 alt={"logo user"}/>
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                        <MenuComponent
                            classes={[classes.sectionDesktop]}
                            isOpen={isMenuOpen}
                            toggleOpen={handleMenuToggle}
                            menuItems={MenuItemsDesktop}
                            mobile={false}
                        />
                        <MenuComponent
                            classes={[classes.sectionMobile]}
                            isOpen={isMenuOpen}
                            toggleOpen={handleMenuToggle}
                            menuItems={MenuItemsMobile}
                            mobile={true}
                        />
                        {authToken && (
                            <>
                                <Menu
                                    anchorOrigin={{
                                        horizontal: 'left',
                                        vertical: 'bottom',
                                    }}
                                    anchorReference="anchorEl"
                                    id="simple-menu"
                                    anchorEl={profileMenuOpenAnchorEl}
                                    keepMounted
                                    open={Boolean(profileMenuOpenAnchorEl)}
                                    onClose={handleProfileMenuClose}
                                    getContentAnchorEl={null}
                                >
                                    {currentUser ?
                                        currentUser.company.type === "dreal" ? [(
                                            <MenuItem
                                                onClick={() => {
                                                    handleProfileMenuClose();
                                                    logout();
                                                }}
                                                disableRipple={true}
                                                className={clsx(classes.fontFamily, classes.loggout)}
                                                key={"logout"}
                                            >
                                                {Locale.trans('logout')}
                                            </MenuItem>
                                        )] : [(
                                            <MenuItem
                                                onClick={() => {
                                                    history.push("/settings/users/" + currentUser.id);
                                                    handleProfileMenuClose();
                                                }}
                                                disableRipple={true}
                                                className={classes.fontFamily}
                                                key={"profile.my"}
                                            >
                                                {Locale.trans('profile.my')}
                                            </MenuItem>
                                        ), (
                                            <MenuItem
                                                onClick={() => {
                                                    history.push("/profile/notifications");
                                                    handleProfileMenuClose();
                                                }}
                                                className={classes.fontFamily}
                                                disableRipple={true}
                                                key={"profile.notifications"}
                                            >
                                                {Locale.trans('profile.notifications')}
                                            </MenuItem>
                                        ), (
                                            <MenuItem
                                                onClick={() => {
                                                    handleProfileMenuClose();
                                                    logout();
                                                }}
                                                disableRipple={true}
                                                className={clsx(classes.fontFamily, classes.loggout)}
                                                key={"logout"}
                                            >
                                                {Locale.trans('logout')}
                                            </MenuItem>
                                        )]
                                        :
                                        (<Skeleton variant="rect" animation="wave" height={150} width="100%"/>)
                                    }
                                </Menu>
                            </>
                        )}
                    </>
                )
            }}
        </AuthConsumer>
    );

};
export default AppBarComponent;
