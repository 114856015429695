
import useCurrentUser from "shared/src/hooks/useCurrentUser";
import {useSnackbar} from "notistack";
import {Locale} from "ias-lib";
import {useEffect} from "react";
import Parameters from 'parameters';


function useIsGranted() {
    const {currentUser, loading, error} = useCurrentUser();
    const {enqueueSnackbar} = useSnackbar();

    let isGranted = (role: string) => {
        if(!error && !loading) {
            if(currentUser?.roles.indexOf(role) !== -1) {
                return true;
            }
            if(Parameters.env === 'dev') {
                console.debug(`Role not found: ${role} in ${currentUser.roles}`);
            }
        }
        return false;
    };

    useEffect(() => {
        if(error) {
            enqueueSnackbar(Locale.trans("error.current_user"), {variant: "error"});
        }
    }, [error, enqueueSnackbar]);


    return {
        loading,
        isGranted
    }
}

const CLIENT = 'COMPANY_CLIENT';
const PARTNER = 'COMPANY_PARTNER';
const ADMIN = 'COMPANY_ADMIN';
const DREAL = 'COMPANY_DREAL';
const ADMIN_EMPLOYEE = 'admin';


const ROLE_USER_MANAGE = 'userManagement';
const ROLE_DREAL = 'dreal';
const ROLE_TRANSFERT_MARKETPLACE = 'decision';
const ROLE_ACCEPT_PARTNER = 'acceptPartner';
const ROLE_BLOCK_PARTNER = 'blockPartner';
const ROLE_CLOSE_PARTNER = 'closePartner';
const ROLE_CREATE_PROVIDER = 'createProvider';
const ROLE_CLOSE_PROVIDER = 'closeProvider';
const ROLE_UPDATE_PROVIDER = 'modifyProvider';
const ROLE_DASHBOARD = 'dashboard';
const ROLE_CONFIRM_BOOKING = 'confirmBooking';
const ROLE_DELETE_BOOKING = 'deleteBooking';
const ROLE_SEND_QUOTATION = 'sendQuotation';
const ROLE_DELETE_QUOTATION = 'deleteQuotation';
const ROLE_ACCEPT_CLIENT = 'acceptClient';
const ROLE_BLOCK_CLIENT = 'blockClient';
const ROLE_CLOSE_CLIENT = 'closeClient';
const ROLE_ACCESS_CLIENT_PAYMENTS = "accessClientPayments";
const ROLE_MODIFY_CLIENT_PAYMENTS = "modifyClientPayments";
const ROLE_ACCESS_PARTNER_PAYMENTS = "accessPartnerPayments";
const ROLE_MODIFY_PARTNER_PAYMENTS = "modifyPartnerPayments";

export {
    useIsGranted,
    CLIENT,
    PARTNER,
    ADMIN,
    DREAL,
    ADMIN_EMPLOYEE,
    ROLE_USER_MANAGE,
    ROLE_DREAL,
    ROLE_TRANSFERT_MARKETPLACE,
    ROLE_ACCEPT_PARTNER,
    ROLE_BLOCK_PARTNER,
    ROLE_CLOSE_PARTNER,
    ROLE_CREATE_PROVIDER,
    ROLE_CLOSE_PROVIDER,
    ROLE_UPDATE_PROVIDER,
    ROLE_DASHBOARD,
    ROLE_CONFIRM_BOOKING,
    ROLE_DELETE_BOOKING,
    ROLE_SEND_QUOTATION,
    ROLE_DELETE_QUOTATION,
    ROLE_ACCEPT_CLIENT,
    ROLE_BLOCK_CLIENT,
    ROLE_CLOSE_CLIENT,
    ROLE_ACCESS_CLIENT_PAYMENTS,
    ROLE_MODIFY_CLIENT_PAYMENTS,
    ROLE_ACCESS_PARTNER_PAYMENTS,
    ROLE_MODIFY_PARTNER_PAYMENTS
}
