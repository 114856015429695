import useCurrentUser from "../hooks/useCurrentUser";
import { Locale } from "ias-lib";
import * as React from "react";
import {useEffect, useState} from "react";
import { useMutation, gql } from '@apollo/client';
import moment from "moment";

const CHANGE_USER_LANGUAGE = gql`
    mutation changeUserLanguage($locale: String!) {
        changeUserLanguage(locale: $locale) {
            id
            locale
        }
    }
`;

type Props = {}

type supportedLocales = 'fr'|'en';

type State = {
    changeLocale: (string: supportedLocales) => void;
    locale: supportedLocales;
}

const Context = React.createContext<State>({
    changeLocale: () => {},
    locale: 'fr',
});

const LocaleContext = Context;


const LocaleProvider: React.FunctionComponent<Props> = ({children}) => {
    const [changeUserLocale] = useMutation(CHANGE_USER_LANGUAGE);
    const {currentUser} = useCurrentUser();

    const [locale, setLocale] = useState<supportedLocales>('fr');

    function changeLocale (value: supportedLocales) {
        setLocale(value);

        Locale.setLocale(value);
    }

    useEffect(() => {
        const getLocale = (): supportedLocales => {
            if (currentUser?.locale === 'fr') {
                return 'fr';
            }
            if (currentUser?.locale === 'en') {
                return 'en';
            }
            if (navigator.languages !== undefined) {
                if (navigator.languages[0].includes('fr')) {
                    return 'fr' 
                }
            }
            else {
                if (navigator.language.includes('fr')) {
                    return 'fr';
                }
            }
            return 'en';
        }
    
        const localeToSet = getLocale();

        if (currentUser && !currentUser.locale) {
            changeUserLocale({variables: {
                locale: localeToSet,
            }});
        }

        moment.locale(localeToSet);
        changeLocale(localeToSet);
    }, [currentUser]);

    return (
        <Context.Provider value={{
            locale,
            changeLocale
        }}>
            {children}
        </Context.Provider>
    );
};
const LocaleConsumer= Context.Consumer;
export default LocaleContext;
export {
    LocaleContext,
    LocaleProvider,
    LocaleConsumer,
}