import * as React from 'react';
import {Locale} from "ias-lib";
import {useField} from "react-form";
import {TextField} from "@material-ui/core";
import "./UsernameField.scss";

function validateUserName(value: string) {
    if (!value) {
        return Locale.trans('login.error.no_username');
    }
    return false;
}

type FieldProps = {
    className?: string;
}

const UsernameField: React.FunctionComponent<FieldProps>  = ({className}) => {
    const {
        meta: {error, isTouched},
        getInputProps
    } = useField("username", {
        validate: validateUserName,
        defaultValue: "",
    });

    return (
        <TextField
            className={className + " username-field"}
            {...getInputProps()}
            error={(!!error && !isTouched)}
            helperText={error}
            id={'username'}
            label={Locale.trans('login.username')}
            variant="outlined"
            required
            InputProps={{
                classes: {
                    input: "username-field-input"
                }
            }}
        />
    )
}
export default UsernameField;
