import React from "react";
import clsx from "clsx";
import {Typography} from "@material-ui/core";
import logo from "shared/src/images/logo-fleetever.svg";
import logoIcon from 'shared/src/images/logo-icon-v2.png';
import menu from 'shared/src/images/menu-35.svg';
import {Locale} from "ias-lib";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

type Props = {
    isMenuOpen: boolean;
    handleMenuToggle: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
            marginLeft: '0.3rem',
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        maxHeight: {
            height: '100%',
        },
        title: {
            //display: 'block',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            backgroundColor: '#101E2C',
            color: 'white',
            marginLeft: '5%',
            marginRight: '5%',
        },
    }
));
export const Logo: React.FunctionComponent<Props> = ({isMenuOpen, handleMenuToggle}) => {
    const classes = useStyles();
    return (
        <>
            <img
                style={{marginLeft: '0',}}
                src={isMenuOpen ? logo : logoIcon}
                alt={Locale.trans("logo.fleetever")}
                height={"30px"}
                className={"desktop"}
            />
            <img
                style={{marginLeft: '0',}}
                src={logoIcon}
                alt={Locale.trans("logo.fleetever")}
                height={"30px"}
                className={"mini"}
            />
            <div
                className={clsx(
                    classes.sectionMobile,
                    classes.grow,
                    classes.maxHeight
                )}
            >
                <Typography
                    className={clsx(
                        classes.title,
                        classes.grow
                    )}
                    variant="h6"
                    noWrap
                    onClick={handleMenuToggle}
                >
                    <img
                        src={menu}
                        style={{marginRight: '10px'}}
                        alt={"logo menu"}
                    />
                    Menu
                </Typography>
            </div>
        </>
    );
}


export default Logo;