import React, {lazy, Suspense, useRef} from 'react';

import './Layout.scss';
import { LoaderOverlay } from 'components';
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";


import AppBarComponent from "../components/MaterialUi/AppBarComponent";
import {ADMIN, DREAL, useIsGranted} from "../services/useIsGranted";
import useCurrentUser from "shared/src/hooks/useCurrentUser";
import {Route, Switch} from "react-router-dom";
import e404 from "shared/src/views/error/404";
import {ScrollToTopContext} from "shared/src/contexts/ScrollToTopContext";



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: '#efefef',
        minHeight: '100vh',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        brackground: '#101e2c',
        width: drawerWidth,
        flexShrink: 0,
        borderTop: '3px solid #e5e5e5',
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    contentContainer: {
        height: '100vh',
        width: '100%',
    },
    content: {
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: -drawerWidth,
        borderTop: '3px solid #e5e5e5',
        width: '100%',
        overflow: 'auto',
        height: 'calc(100vh - 64px)',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

type Props = {
    location: object;
    history: any;
};


const Home = lazy(() => import('views/Home'));
const Client = lazy(() => import('views/client/Main'));
const Partners = lazy(() => import('views/partner/Main'));
const Payments = lazy(() => import('views/payments/Main'));
const Transports = lazy(() => import('views/transports/Main'));
const Quotations = lazy(() => import('views/quotations/Main'));
const Bookings = lazy(() => import('views/booking/Main'));
const Marketplace = lazy(() => import('views/marketplace/Main'));
const Reminders = lazy(() => import('views/reminders/Main'));
const Providers = lazy(() => import('views/providers/Main'));
const Settings = lazy(() => import('views/settings/Main'));
const Tools = lazy(() => import('views/tools/index'));
const Notifications = lazy(() => import('views/profile/Notifications'));



export default function AppLayout({ location, history }: Props) {
    const container = useRef<HTMLDivElement>(null);
    const {loading, isGranted} = useIsGranted();
    const {currentUser, loading: userLoading} = useCurrentUser();

    const scrollToTop = () => {
        if(container !== null && container.current !== null) {
            container.current.scrollTo({top: 0, behavior: "smooth"});
        }
    };
    const classes = useStyles();

    const { pathname } = location as {pathname: string};

    let openMenus = [];
    let selectedMenu = pathname;
    if (selectedMenu.startsWith('/settings')) {
        selectedMenu = '/settings/utilisateurs';
        openMenus.push('settings');
    }


    if(!loading && !isGranted(ADMIN) && !isGranted(DREAL)) {
        history.push('/unauthorised');
    }

    if(!userLoading && !currentUser?.enabled){
        history.push('/inactive');
    }

    return (
        <>
            <div className={classes.root} id={"root"}>
                <CssBaseline />
                <AppBarComponent />
                <main
                    className={clsx(classes.contentContainer)}
                >
                    <div className={classes.drawerHeader} />
                    <div className={clsx(classes.content)} ref={container}>
                        <ScrollToTopContext.Provider value={{scrollToTop}}>
                        <div className={"main-content"}>
                            <Suspense
                                fallback={<LoaderOverlay loading={true} />}
                            >
                                <Switch>
                                    <Route path="/profile/notifications" component={Notifications} />
                                    <Route exact path="/"  component={Home} />
                                    <Route path="/quotations" component={Quotations} />
                                    <Route path="/booking" component={Bookings} />
                                    <Route path="/transport" component={Transports} />
                                    <Route path="/marketplace" component={Marketplace} />
                                    <Route path="/clients" component={Client} />
                                    <Route path="/partners" component={Partners} />
                                    <Route path="/payments" component={Payments} />
                                    <Route path="/reminders" component={Reminders} />
                                    <Route path="/providers" component={Providers} />
                                    <Route path="/settings" component={Settings} />
                                    <Route path="/tools" component={Tools} />
                                    <Route component={e404} />
                                </Switch>
                            </Suspense>
                        </div>
                        </ScrollToTopContext.Provider>
                    </div>
                </main>
            </div>
        </>
    );
}
