import * as React from "react";
import NotificationButton from "./NotificationButton";
import {Grid, Menu} from "@material-ui/core";
import {default as NotificationComponent} from "./Notification";
import {Locale} from "ias-lib";
import {useSnackbar} from "notistack";
import {gql, useMutation} from "@apollo/client";
import {useState} from "react";

import './Notifications.scss';
import {useHistory} from "react-router";

type Props = {
    notifications: Notification[];
    buttonClassName?: string;
    total?: number;
    refetch: () => void;
}

const DISABLE_NOTIFICATION = gql`
    mutation disableNotification ($id: UUID!) {
        disableNotification (id: $id) {
            id
            isActive
            date
            targetReference
            description
            title
            user {
                id
            }
            link
        }
    }
`;

export const Notifications: React.FunctionComponent<Props> = ({buttonClassName, notifications, total, refetch}) => {
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();

    const [notificationsMenuOpenAnchorEl, setNotificationsMenuOpenAnchorEl] = useState<null | HTMLElement>(null);
    const [showAll, setShowAll] = useState<boolean>(false);

    const [disableNotification] = useMutation(DISABLE_NOTIFICATION);

    const handleNotificationsMenuClose = () => {
        setNotificationsMenuOpenAnchorEl(null);
        setShowAll(false);
    };
    const handleNotificationsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setNotificationsMenuOpenAnchorEl(event.currentTarget);
    };

    const handleNotificationClick = (notif: Notification) => {
        history.push(`${notif.link}`);
        disableNotification({variables: {
                id: notif.id,
            }}).then(() => {
            refetch();
        }).catch(() => {
            enqueueSnackbar(Locale.trans('notifications.disable.error'), {variant: "error"});
        });
    };
    const onClick = (notif: Notification) => {
        handleNotificationClick(notif);
        handleNotificationsMenuClose();
    }


    return (
        <>
            <NotificationButton
                buttonClassName={buttonClassName}
                onOpen={handleNotificationsMenuOpen}
                nbNotification={total || 0}
                isOpen={!!notificationsMenuOpenAnchorEl}
            />
            <Menu
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                anchorReference="anchorEl"
                id="menu-notifications"
                anchorEl={notificationsMenuOpenAnchorEl}
                keepMounted
                open={Boolean(notificationsMenuOpenAnchorEl)}
                onClose={handleNotificationsMenuClose}
                getContentAnchorEl={null}
            >
                <div className="menu-notifications__container">
                    <div
                        className={"menu-notifications__notification summary"}
                    >
                        <p>
                            {Locale.trans('notifications.summary', {NUMBER: total})}
                        </p>
                    </div>
                    {(showAll ? notifications : notifications.slice(0,5)).map((notif: Notification) => {
                        return(
                            <div
                                className={"menu-notifications__notification"}
                                key={notif.id}
                            >
                                <NotificationComponent
                                    notif={notif}
                                    handleNotificationClick={onClick}
                                />
                            </div>
                        );
                    })}
                    <div className={"menu-notifications__notification showAll"} onClick={() => setShowAll(!showAll)}>
                        <span>{showAll ? Locale.trans("reduce") : Locale.trans("showAll")}</span>
                    </div>
                </div>
            </Menu>
        </>
    );
}
export default Notifications;    