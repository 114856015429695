import {gql} from "@apollo/client";


const GET_NOTIFICATIONS = gql`
query notifications {
    notifications {
        notifications {
            id
            isActive
            date
            targetReference
            description
            title
            user {
                id
            }
            link
        }
        total
    }
}
`;

const CHANGE_USER_LANGUAGE = gql`
    mutation changeUserLanguage($locale: String!) {
        changeUserLanguage(locale: $locale) {
            id
            locale
        }
    }
`;

export {
    GET_NOTIFICATIONS,
    CHANGE_USER_LANGUAGE,
}