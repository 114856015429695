import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Fade from '@material-ui/core/Fade';
import IconButton from "@material-ui/core/IconButton";
import { Locale } from 'ias-lib';
import './MenuArrow.scss';

const useStyles = makeStyles((theme) => ({
    arrowBlock: {
        display: 'inline',
        position: 'absolute',
        left: '8px',
        top: '12px',
    },
    arrowButton: {
        position: 'absolute',
        left: '0.9rem',
        top: '0.28rem',
        transform: 'scale(0.85)',
    },
    arrowOutline: {
        position: 'absolute',
        left: '0.53rem',
        top: '-1px',
        transform: 'scale(0.8)',
    },
    container: {
        overflow: "hidden",
    },
    text: {
        fontSize: '11pt',
        position: 'fixed',
        bottom: '2.4%',
        left: '3.8%',
        // color: "red",
    },
}));

function MenuArrowOpen() {

    return (
        <div className="menu-arrow-block">
            <div className="menu-arrow">
                <ChevronLeftOutlinedIcon className="menu-arrow-button" />
                <RadioButtonUncheckedIcon className="menu-arrow-button-outline" fontSize="large" />
            </div>
            <Fade
                in={true}
                timeout={650}>
                <span className={"text"}>
                    {Locale.trans('toggleMenu')}
                </span>
            </Fade>
        </div>
    )
}
type Props = {
    isOpen: boolean;
    toggleOpen: () => void;
};

const MenuArrow: React.FunctionComponent<Props> = ({isOpen, toggleOpen}) => {
    return (
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleOpen}
            edge="start"
            className={"menu-arrow-container"}
            disableRipple={true}
        >
            {isOpen ? (
                <MenuArrowOpen/>
            ) : (
                <MenuArrowClosed />
            )}
        </IconButton>
    )
};

function MenuArrowClosed() {

    return (
        <div className="menu-arrow-block closed">
            <div className="menu-arrow">
                <ChevronRightOutlinedIcon className="menu-arrow-button" />
                <RadioButtonUncheckedIcon className="menu-arrow-button-outline" fontSize="large" />
            </div>
            <Fade
                in={true}
                timeout={650}>
                <span className={"text"}>
                    &nbsp;
                </span>
            </Fade>
        </div> 
    )
}

export {MenuArrowOpen, MenuArrowClosed, MenuArrow};
