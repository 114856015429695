import {ApolloClient, InMemoryCache} from '@apollo/client';
import {ApolloLink, concat} from 'apollo-link';
import { onError } from 'apollo-link-error';
import Parameters from '../parameters';
import {UNSAFE_logout} from "../contexts/AuthContext";
import {GraphQLError} from "graphql";
import { createUploadLink } from 'apollo-upload-client';
import {Logger} from "./Logger";


let AUTH_TOKEN: AuthTokenType | null = null;

const authMiddleware = new ApolloLink((operation:any, forward:any) => {
    if(AUTH_TOKEN) {
        // add the authorization to the headers
        operation.setContext({
            headers: {
                "X-Auth-Token": AUTH_TOKEN.value,
            },
        });
    }

    return forward(operation);
});
const loggOutAfterware = onError(({ graphQLErrors, networkError }) => {
    if (!graphQLErrors && !networkError) {
        return;
    }
    if(networkError) {
        //@ts-ignore
        //@ts-ignore
        if (networkError.hasOwnProperty("statusCode") && networkError.statusCode === 401) {
            //TODO go to unauthorised
        }
    }
    if(graphQLErrors) {
        //@ts-ignore
        const error = graphQLErrors.filter((error: GraphQLError) => error.unauthenticated)[0];
        Logger.logError(graphQLErrors)
        if (error) {
            UNSAFE_logout();
        }
    }
});

const cache: InMemoryCache = new InMemoryCache({
    // cacheRedirects: {
    //     Query: {
    //         //@ts-ignore
    //         company: (_:any, args:any) => toIdValue(cache.config.dataIdFromObject({ __typename: 'Company', id: args.id })),
    //         //@ts-ignore
    //         companyContact: (_:any, args:any) => toIdValue(cache.config.dataIdFromObject({ __typename: 'CompanyContact', id: args.id })),
    //     },
    // },
});


//@ts-ignore
const uploadLink = new createUploadLink({
    uri: Parameters.ApiUrl + 'graphql/',
});

const client = new ApolloClient({
    cache,
    //@ts-ignore
    link: loggOutAfterware.concat(concat(authMiddleware, uploadLink))
});

function updateAuthToken(authToken: AuthTokenType | null) {
    AUTH_TOKEN = authToken;
}
export default client;

export {
    client,
    updateAuthToken
};
