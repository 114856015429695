import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Collapse, SwipeableDrawer} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router-dom';
import {MenuArrow} from 'shared/src/components/Layout/MenuArrow';

import './Menu.scss';
import Version from "../Version/Version";
import useCurrentUser from "../../hooks/useCurrentUser";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    drawer: {
        position: 'relative',
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerInside: {
        background: '#101e2c',
        color: 'white',
        [theme.breakpoints.up('md')]: {
            paddingTop: '64px',
        },
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        color: 'white',

        position: 'absolute',
        bottom: '4px',
        left: 1,
        right: 1,
    },
    subMenuItems: {
        paddingLeft: "12px",
        color: '#798188',
    },
    bookmark: {
        borderLeft: "2px solid #798188",
        borderRight: "2px solid #798188",
        borderTop: "10px solid #798188",
        borderBottom: "10px solid #798188",

        height: "100%",
        marginRight: '15px'
    }
}));

type MenuItem = MenuItemToRender | MenuItemRendered;
type MenuItemToRender = {
    name: string;
    url: string;
    icon?: JSX.Element;
    iconSelected?: JSX.Element;
    subMenus?: MenuItemToRender[];
    hidden?: boolean;
};
type MenuItemRendered = { render: () => JSX.Element };

type Props = {
    isOpen: boolean;
    toggleOpen: () => void;
    menuItems: MenuItem[];
    mobile: boolean;
    classes: any;
};

export default function Menu({
                                 isOpen,
                                 toggleOpen,
                                 menuItems,
                                 mobile,
                                 classes: classesInjected,
                             }: Props) {
    const classes = useStyles();

    const location = useLocation();
    const history = useHistory();

    const {currentUser} = useCurrentUser();

    const content = (
        <>
            <List
                className="menu-list"
            >
                {menuItems.map((text, index) => {
                    if ((text as MenuItemRendered).render !== undefined) {
                        return (text as MenuItemRendered).render();
                    }
                    var item = text as MenuItemToRender;

                    let isSelected = false;
                    if (item.subMenus) {
                        item.subMenus.forEach((subItem: MenuItemToRender) => {
                            isSelected = isSelected || location.pathname.includes(subItem.url);
                        })
                    } else {
                        isSelected = location.pathname === item.url || (location.pathname.startsWith(item.url) && item.url !== "/");
                    }
                    if(item.hidden){
                        return(<React.Fragment></React.Fragment>);
                    }

                    const toReturn = [(
                        <ListItem
                            className={`menu-list-item ${isOpen ? "" : "closed"} ${isSelected ? "selected" : ''}`}
                            selected={isSelected}
                            button
                            disableRipple={true}
                            key={item.url}
                            onClick={() => history.push(item.url)}
                        >
                            <div className={"menu-container"}>
                                <div className={"menu-icon"}>
                                    {isSelected ? item.iconSelected : item.icon}
                                </div>
                                <div
                                    className={`menu-text ${isSelected ? "selected" : null}`}
                                >
                                    {item.name.toLowerCase()}
                                </div>
                            </div>
                        </ListItem>
                    )];
                    if (item.subMenus && isOpen) {
                        toReturn.push(<Collapse in={isSelected} unmountOnExit key={"collapse" + index}>
                                <List component={"div"}>
                                    {item.subMenus.map((item: MenuItemToRender) => {
                                        const subMenuSelected = location.pathname.includes(item.url);
                                        return (
                                            <ListItem key={item.url} button disableRipple className={classes.subMenuItems}
                                                      onClick={() => history.push(item.url)}>
                                                <ListItemIcon>

                                                </ListItemIcon>
                                                {subMenuSelected &&
                                                <div className={classes.bookmark}/>
                                                }
                                                <ListItemText primary={item.name}/>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Collapse>
                        );
                    }

                    return (
                        <React.Fragment key={index}>
                            {toReturn}
                        </React.Fragment>
                    );
                })}
            </List>
            <div className={classes.toolbar}>
                <MenuArrow isOpen={isOpen} toggleOpen={toggleOpen}/>
            </div>
        </>
    );

    if (mobile) {
        return (
            <SwipeableDrawer
                className={clsx(classes.drawer, ...classesInjected)}
                classes={{
                    paper: clsx(classes.drawerInside),
                }}
                onOpen={toggleOpen}
                onClose={toggleOpen}
                open={isOpen}
            >
                <Version/>
                {content}
            </SwipeableDrawer>
        );
    }
    return (
        <Drawer
            variant="permanent"
            className={clsx(
                classes.drawer,
                {
                    [classes.drawerOpen]: isOpen,
                    [classes.drawerClose]: !isOpen,
                },
                ...classesInjected,
            )}
            classes={{
                paper: clsx(
                    {
                        [classes.drawerOpen]: isOpen,
                        [classes.drawerClose]: !isOpen,
                    },
                    classes.drawerInside,
                ),
            }}
        >
            {currentUser?.company.type === "m2r" &&
            <Version/>
            }
            {content}
        </Drawer>
    );
}
