import * as React from 'react';
import {IconButton, Menu, MenuItem} from '@material-ui/core';
import ReactCountryFlag from "react-country-flag";
import clsx from "clsx";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {useContext, useEffect} from "react";
import LocaleContext from "../../contexts/LocaleContext";
import {useMutation} from "@apollo/client";
import {CHANGE_USER_LANGUAGE} from "admin/src/components/MaterialUi/gql";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import useCurrentUser from "../../hooks/useCurrentUser";

type LocalesFlag = 'FR'|'GB';

const classMapLocalesToFlag: {[key: string]: LocalesFlag} = {
    fr: "FR",
    en: "GB",
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBarIcon: {
            color: '#101e2c',
            marginRight: 0,
            borderRadius: '15px',

            "&:hover": {
                backgroundColor: 'rgba(0,0,0,0.07)'
            }
        },
        menuOpen: {
            background: 'rgba(0,0,0,0.07)',
        },
        languageMenu: {
            borderRadius: '0 0 8px 8px',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%)',
            border: '1px solid rgba(62,62,62,0.1)',
            width: '110px',
            margin: '6px -8px',
            color: 'X',
            fontFamily: 'Eesti, sans-serif',
            '& > *' : {
                boxShadow: 'inset 0px 3px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%)',
            }
        },
    }),
);
type Props = {
}

const LanguageSelect: React.FunctionComponent<Props> = ({}) => {
    const classes = useStyles();

    const [languageMenuOpenAnchorEl, setLanguageMenuOpenOpenAnchorEl] = React.useState<null | HTMLElement>(null);

    const {locale, changeLocale} = useContext(LocaleContext);
    const {currentUser} = useCurrentUser();


    const [changeUserLanguage] = useMutation(CHANGE_USER_LANGUAGE);

    const setNewLocale = (newLocale: string) => {
        changeUserLanguage({
            variables: {
                locale: newLocale
            }
        });
    }

    const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setLanguageMenuOpenOpenAnchorEl(event.currentTarget);
    };

    const handleLanguageMenuClose = () => {
        setLanguageMenuOpenOpenAnchorEl(null);
    };

    const handleLanguageChange = (localeToUpdate: supportedLocales) => {
        handleLanguageMenuClose();
        setNewLocale(localeToUpdate);
        changeLocale(localeToUpdate);
    };

    useEffect(() => {
        if(currentUser && locale && !currentUser.locale) {
            setNewLocale(locale);
        }
    }, [locale, currentUser])

    return(
        <>
            <IconButton
                className={clsx({
                    [classes.menuOpen]:languageMenuOpenAnchorEl
                }, classes.appBarIcon)}
                onClick={handleLanguageMenuOpen}
                disableRipple
            >
                <ArrowDropDownIcon style={{marginLeft: '-10px'}} />
                <ReactCountryFlag
                    countryCode={classMapLocalesToFlag[locale]}
                    style={{
                        width: '1.2em',
                        height: '1.2em',
                        alignSelf: 'center',
                    }}
                    svg
                />
            </IconButton>
            <Menu
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                classes={{
                    paper: classes.languageMenu,
                }}
                anchorReference="anchorEl"
                id="simple-menu"
                anchorEl={languageMenuOpenAnchorEl}
                keepMounted
                open={Boolean(languageMenuOpenAnchorEl)}
                onClose={handleLanguageMenuClose}
                getContentAnchorEl={null}
            >
                {locale !== "en" &&
                <MenuItem disableRipple onClick={() => handleLanguageChange('en')}>
                    EN
                    <ReactCountryFlag
                        countryCode="GB"
                        style={{
                            width: '1.8em',
                            height: '1.8em',
                            marginLeft: '16px',
                        }}
                        svg
                    />
                </MenuItem>
                }
                {locale !== "fr" &&
                <MenuItem disableRipple onClick={() => handleLanguageChange('fr')}
                >
                    FR
                    <ReactCountryFlag
                        countryCode="FR"
                        style={{
                            width: '1.8em',
                            height: '1.8em',
                            marginLeft: '16px',
                        }}
                        svg
                    />
                </MenuItem>
                }
            </Menu>
        </>
    );
};

export default LanguageSelect;