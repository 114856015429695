import React from "react";
import "./Version.scss";
import Parameters from "client/src/parameters";

type Props = {};

const Version: React.FunctionComponent<Props> = () => {

    return (
        <div className={"app-version"}>
          v{Parameters.appVersion}
        </div>
    );
};

export default Version