import React from 'react';
import './LoaderOverlay.scss';
import {Backdrop, CircularProgress, createStyles, Theme} from '@material-ui/core'
import {makeStyles} from "@material-ui/core/styles";

import clsx from "clsx";

type Props = {
    loading: boolean;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            zIndex: 100,
            color: "white",
        },
    })
);

export default React.memo(({loading}: Props) => {
    const classes = useStyles();
    return (
        <div className={"list-page"}>
            <Backdrop
                open={loading}
                className={clsx(classes.root)}
            >
                <CircularProgress
                    color="inherit"
                    size={80}
                    thickness={6.9}
                />
            </Backdrop>

        </div>
    );
});
