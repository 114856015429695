
const logInfo = (args: any) => {
    console.log(args);
}

const logError = (args: any) => {
    console.error(args);
}

export const Logger = {
    logInfo,
    logError,
}