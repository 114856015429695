import gql from 'graphql-tag';
import {useQuery} from '@apollo/client';
import {FAST_POLL_RATE} from "../const/PollRate";
import {useRef} from "react";

type ReturnType = {
    currentUser: TUser | null;
    loading: boolean;
    error: any;
    refetch: any;
    isDreal: boolean,
}

type TUser = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    roles: string[];
    company: TCompany;
    phoneNumber: string;
    enabled: boolean;
    role: string;
    locale: supportedLocales;
}

type TCompany = {
    id: UUID;
    status: CompanyStatus;
    reference: string;
    name: string;
    type: CompanyType;
    transportModes: TransportChoice[];
    departureCountries: string[];
    arrivalCountries: string[];
    inttraId: string | null;
}

const GET_CURRENT_USER = gql`
{
    currentUser {
        id
        firstName
        lastName
        email
        role
        roles
        company {
            id
            status
            reference
            name
            type
            transportModes
            departureCountries
            arrivalCountries
            inttraId
        }
        phoneNumber
        enabled
        locale
    }
} 
`;


const useCurrentUser: () => ReturnType = () => {
    const errorCount = useRef(0);
    const {data, loading, error, refetch} = useQuery(GET_CURRENT_USER, {
        pollInterval: FAST_POLL_RATE,
    });
    let currentUser = null;
    if (!loading && !error) {
        if (data?.currentUser) {
            errorCount.current = 0;
            currentUser = data.currentUser;
        } else {
            if (errorCount.current > 10) {
                currentUser = undefined;
            } else {
                errorCount.current++;
            }
        }
    }
    const isDreal = currentUser?.company.type === "dreal";
    return {
        isDreal,
        currentUser,
        loading,
        error,
        refetch
    }
};
export default useCurrentUser;
