import * as React from "react";
import {Grid, MenuItem} from "@material-ui/core";
import moment from "moment";
import {Locale} from "ias-lib";
import './Notification.scss';

type Props = {
    notif: Notification;
    handleNotificationClick: (notif: Notification) => void;
}


export const Notification: React.FunctionComponent<Props> = ({notif, handleNotificationClick}) => {
    return (
        <>
            <MenuItem onClick={() => handleNotificationClick(notif)} className={`notification ${notif.isActive ? 'active': 'inactive'}`}>
                <div className="notification-line">
                    <div>
                        <div className={"notification__target-reference"}>{notif.targetReference} </div>
                    </div>
                    <div>
                        <p className={"notification__description"}>
                            {Locale.trans(notif.description)}
                        </p>
                    </div>
                    <div className={"notification__date"}>
                        <div>{moment(notif.date).format(Locale.trans("notification.date_format"))}</div>
                    </div>
                </div>
            </MenuItem>
        </>
    );
}
export default Notification;