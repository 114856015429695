import * as React from 'react';
import {Locale} from "ias-lib";
import {useState} from "react";
import {useField} from "react-form";
import {IconButton, InputAdornment, TextField} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import "./PasswordField.scss";

type FieldProps = {
    className?: string;
    field? : string;
    sameAs?: string;
    required?: boolean;

}


const PasswordField: React.FunctionComponent<FieldProps> = ({required, className, field, sameAs}) => {

    const [typePassword, setTypePassword] = useState("password");
    field = field || "password"
    const {
        meta: {error, isTouched},
        getInputProps
    } = useField(field, {
        validate: validatePassword,
        defaultValue: "",
    });


    function validatePassword(value: string) {
        if (!value && (required === undefined || required)) {
            return Locale.trans('login.error.no_password');
        }
        if(!value) {
            return false;
        }
        if(sameAs && (value !== sameAs)){
            return Locale.trans('login.error.password_no_match');
        }
        return false;
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <TextField
            {...getInputProps()}
            className={className + " password-field"}
            error={(!!error && !isTouched)}
            helperText={error}
            id={field}
            label={Locale.trans('login.' + field)}
            type={typePassword}
            autoComplete="current-password"
            variant="outlined"
            required={required === undefined ? true: required}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setTypePassword(typePassword === 'password' ? 'text' : 'password')}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {typePassword === 'password' ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                        </IconButton>
                    </InputAdornment>
                ),
                classes: {
                    input: "password-field-input"
                }
            }}
        />
    )
}
export default PasswordField;
