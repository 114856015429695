
import * as React from "react";
import {useContext, useState} from "react";

type Props = {}

type State = {
    isMenuOpen: boolean;
    toggleMenuOpen:  () => void;
}

const Context = React.createContext<State>({
    isMenuOpen: false,
    toggleMenuOpen:  () => {},
});



const MenuManagementProvider: React.FunctionComponent<Props> = ({children}) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(
        localStorage.getItem('sidebar-collapsed') === 'true',
    );

    function toggleMenuOpen () {
        setIsMenuOpen(!isMenuOpen);

        localStorage.setItem('sidebar-collapsed', (!isMenuOpen).toString());
    }
    return (
        <Context.Provider value={{
            isMenuOpen,
            toggleMenuOpen
        }}>
            {children}
        </Context.Provider>
    );
};
const useMenuManagement = () => {
    return useContext(Context)
};
export {
    MenuManagementProvider,
    useMenuManagement,
}